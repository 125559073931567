import React from 'react';
import styled from 'styled-components';
import {compose, withHandlers, withStateHandlers} from 'recompose';
import {range} from 'ramda';
import * as HOC from '@p14/HOC';
import config from '@p14/config';

export const Root = styled.section`
  width: 100%;
  position: relative;
`;

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 800px;
  position: relative;

  @media ${config.media.tabletOnly} {
    height: 485px;
  }

  @media ${config.media.mobile} {
    height: 300px;
  }
`;

export const Container = styled.div`
  white-space: nowrap;
  font-size: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease 0s;
  cursor: ${props => props.isDragging ? 'grabbing': 'grab'};
  will-change: transform;
`;

export const Figure = styled.article`
  white-space: normal;
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  vertical-align: top;
  background-size: cover;
  background-position: center center;
`;

export const Description = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 390px;
  color: #fff;
  text-align: center;
  z-index: 2;

  @media ${config.media.mobile} {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
  }
`;

export const Title = styled.h3`
  font-size: 20px;
  margin: 0 0 30px;
  text-transform: uppercase;
  font-family: 'Austin';
  letter-spacing: 0.17px;
  font-weight: bold;
`;

export const Text = styled.p`
  font-size: 14px;
  margin: 0;
  font-family: 'Geometria';
  line-height: 18px;
`;

export const Pagination = styled.nav`
  height: 6px;
  bottom: 10px;
  left: 50%;
  position: absolute;
  border-radius: 1000px;
  background-color: #d8d8d8;
  transform: translateX(-50%);

  @media ${config.media.mobile} {
    position: relative;
    left: auto;
    bottom: 0;
    transform: none;
    margin: 10px auto 45px;
  }
`;

export const PaginationSlider = styled.div`
  width: 26px;
  position: absolute;
  left: 1px;
  top: 1px;
  height: 4px;
  background-color: #b48b72;
  border-radius: 1000px;
  transition: transform 0.5s ease 0s;
  will-change: transform;
`;

export const Arrow = styled.button`
  top: 0;
  position: absolute;
  left: ${props => props.direction === 'left' ? 0 : 'auto'};
  right: ${props => props.direction === 'right' ? 0 : 'auto'};
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  width: 120px;
  height: 100%;
  transform: ${props => props.direction === 'left' ? 'rotate(180deg)' : 'rotate(0deg)'};

  @media ${config.media.mobile} {
    display: none;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .6);
`;

export const Slide = ({texts, index, image}) => (
  <Figure style={{backgroundImage: `url('${image}')`}}>
    {texts[index] ? (
      <Description>
        {texts[index].titles ? texts[index].titles.map((title, i) => <Title key={i}>{title}</Title>) : null}
        {texts[index].description ? <Text dangerouslySetInnerHTML={{__html: texts[index].description}}/> : null}
      </Description>
    ) : null}

    {texts[index] && (texts[index].titles || texts[index].description) ? <Overlay/> : null}
  </Figure>
);

export const Svg = () => (
  <svg width="23" height="43">
    <path d="M1.5 41.5l20-20m-20-20l20 20" stroke="#FFF" strokeWidth="2" fill="none" strokeLinecap="square"/>
  </svg>
);

export const slides = range(1, 26).map(i => i === 18 ? null : ({
  image: `/static/hamovniki/${i}.jpg`,
  texts: {
    0: {
      titles: ['Москва, какой вы ее любите'],
      description: 'Хамовники – почти остров, одинаково близко к центру и к «зеленому поясу» знаменитых парков вдоль Москва-реки. Старинные усадьбы, где вернувшийся из&nbsp;Италии Гоголь варил пасту, юный Толстой прыгал с&nbsp;балкона, а никому не известный Серов писал вид из&nbsp;окна Репина.',
    },
    4: {
      description: 'По статистике, Хамовники – один из немногих районов столицы, где большая часть семей живут уже не первое поколение. Интеллектуальный, уютный, малоэтажный, пешеходный – здесь почти нет больших магистралей, и гулять можно бесконечно, любуясь смешением архитектуры разных эпох.',
    },
    8: {
      titles: ['Погулять с детьми'],
      description: 'Красивейший монастырь Москвы, Новодевичий, и&nbsp;восхитительный уютный парк вокруг него; парк усадьбы Трубецких с горбатыми мостами или усадьба Толстого – чтобы учить литературу без учебников. Новые любимые маршруты для прогулок, и&nbsp;дом&nbsp;всегда&nbsp;рядом.',
    },
    12: {
      titles: ['В выходные вам не нужно садиться за руль. Если только за руль велосипеда.'],
      description: 'Площадки для тренировок – это новые бары. Беговой клуб в&nbsp;парке Горького, совместные тренировки в&nbsp;Лужниках, бассейн «Чайка» – на расстоянии пробежки. И вся набережная, как повод завести новый велосипед – тут вы точно не будете страдать от&nbsp;недостатка движения.',
    },
    16: {
      titles: ['Собрать портфель?'],
      description: 'Вопрос «куда пойти в школу» в Хамовниках скорее означает – как выбрать лучшее из лучшего. Легендарные Лицей 1535, «пятьдесят седьмая» и&nbsp;другие школы и гимназии с верхних строчек рейтинга – рядом с вами.'
    }
  }
})).filter(Boolean);

export const Slider = ({activeIndex, followedX, onNext, onPrev}) => (
  <Root>
    <Wrapper>
      <Container
        id="hamovniki-slider"
        isDragging={Boolean(followedX)}
        style={{
          transform: `translateX(${(activeIndex * 100 * -1) + (followedX * -1)}%)`,
          transition: followedX ? 'none' : '',
        }}
        >
        {slides.map((slide, index) => <Slide {...slide} index={index} key={index}/>)}
      </Container>
    </Wrapper>

    <Pagination style={{width: 28 * slides.length}}>
      <PaginationSlider style={{transform: `translateX(${activeIndex * 28}px)`}}/>
    </Pagination>

    {activeIndex === 0 ? null : <Arrow direction="left" onClick={onPrev}><Svg/></Arrow>}
    {activeIndex === slides.length - 1 ? null : <Arrow direction="right" onClick={onNext}><Svg/></Arrow>}
  </Root>
);

export const enhance = compose(
  withStateHandlers(
    ({activeIndex = 0}) => ({activeIndex}), {
    onNext: ({activeIndex}) => () => ({activeIndex: activeIndex + 1 > slides.length - 1 ? 0 : activeIndex + 1}),
    onPrev: ({activeIndex}) => () => ({activeIndex: activeIndex - 1 < 0 ? slides.length - 1 : activeIndex - 1}),
  }),
  withHandlers({
    onFollowTouchEnd: props => followedX => followedX > 30 ? props.onNext() : followedX < -30 ? props.onPrev() : null,
  }),
  HOC.withTouch('hamovniki-slider'),
);

export default enhance(Slider);
