import React from 'react';
import Layout from '@p14/layouts';
import * as Components from './_';

export const meta = {
  title: 'Район Хамовники — место где современная жизнь течет в исторических берегах.',
  keywords: 'Пироговская, 14,  Хамовники, жить, квартиры, недвижимость, район, история',
  description: 'Хамовники — почти остров, район одинаково близок к центру и к «зеленому поясу» знаменитых парков вдоль Москва-реки. Старинные усадьбы, где вернувшийся из Италии Гоголь варил пасту, юный Толстой прыгал с балкона, а никому не известный Серов писал вид из окна Репина.',
}

export const Hamovniki = () => (
  <Layout {...meta}>
    <Components.Slider/>
    <Components.Map/>
  </Layout>
);

export default Hamovniki;
