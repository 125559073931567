import React from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link';
import config from '@p14/config';

export const Root = styled.section`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('/static/map.png');
  position: relative;
  width: 100%;
  height: 230px;
`;

export const Button = styled(Link)`
  text-decoration: none;
  width: 199px;
  height: 56px;
  text-align: center;
  line-height: 56px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: ${config.colors.light};
  font-size: 14px;
  background-color: #b48b72;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease 0s;
  will-change: background-color;

  &:hover {
    background-color: #9b9b9b;
  }
`;

export const Map = () => (
  <Root>
    <Button to="/hamovniki/map">Карта района</Button>
  </Root>
);

export default Map;
